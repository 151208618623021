<template>
  <el-col :span="24" class="item" > 
    <div class="wraper">
      <div class="imgWrap"  @click="JumpDetail()">
        <img :alt="info.name | defaultName" :src="info.images+picSuffix | defaultImage" />
      </div>
       
       <div class="hidePrice" v-show="!isqualification">
        资质认证后显示商品价格
      </div>
      <h3 class="shopName" :title="info.name">{{ info.name | defaultName }} </h3>
      <el-col :span="24" class="countdownBox"  v-if="info.promotionType=='FLASH_SALE'">
        <span class="countdownDiv">
            <ProCountDown :startTime="startTime" :endTime="endTime"></ProCountDown>
        </span>
      </el-col>
     

      <!-- 限时拼团 -->
      <div class="specification" v-if="info.promotionType=='FLASH_GROUP'">
        {{info.minOrderNum}}{{info.unit}}起拼/{{info.purchaseNum}}{{info.unit}}已付款
      </div>
     <div class="specification" v-if="info.promotionType=='FLASH_GROUP'">
        <span class="Progress">
          <p :style="{width:info.percentage+'%'}"></p>
         </span>
          <b>已成团</b>
      </div>
       
       <!-- 限时抢购 -->
      <div class="specification" v-if="info.promotionType=='FLASH_SALE'">
         每人限购{{info.countRestrict }}盒
      </div>
      <div class="specification" v-if="info.promotionType=='FLASH_SALE'">
        <span class="Progress">
          <p :style="{width:info.percentage+'%'}"></p>
         </span>
          <b>{{info.percentage}}%</b>
      </div>

      <!-- 套餐 -->
       <div class="specification" v-if="info.promotionType=='COMBO'">
         每人限购{{info.countRestrict }}盒
      </div>
      <div class="specification" v-if="info.promotionType=='COMBO'">
      </div>

      <!-- 特价 -->
      <div class="specification" v-if="info.promotionType=='SPECIALOFFER'">
         效期:{{ info.validDateFromat }}  / {{ info.partSpecification }}
      </div>
      <div class="specification"  v-if="info.promotionType=='SPECIALOFFER'">
         厂家:{{info.manufacturerName}}
      </div>

      <!-- 一元购 -->
      <div class="specification" v-if="info.promotionType=='ONE_BUY'">
         效期:{{ info.validDateFromat }}  / {{ info.partSpecification }}
      </div>
      <div class="specification" v-if="info.promotionType=='ONE_BUY'">
         厂家:{{info.manufacturerName}}
      </div>

      <div class="specification" v-if="info.promotionType!=='FLASH_SALE'">
      </div>

      <!-- {{info.promotionTypeName}}  -->
      <!-- 限时抢购 -->
      <div class="validity" v-if="info.promotionType=='FLASH_SALE'">
        <span class="ProductLabel FLASH_SALELabel" > {{info.promotionTypeName}}</span>
      </div>
      <!-- 限时拼团 -->
      <div class="validity" v-if="info.promotionType=='FLASH_GROUP'">
        <span class="ProductLabel FLASH_GROUPLabel" > 拼团</span>
      </div>
      <!-- 套餐 -->
      <div class="validity" v-if="info.promotionType=='COMBO'">
        <span class="ProductLabel COMBOLabel">套餐</span>
      </div>
      <!-- 一元购 -->
      <div class="validity" v-if="info.promotionType=='ONE_BUY'">
        <span class="ProductLabel ONE_BUYLabel" > {{info.promotionTypeName}}</span>
      </div>
      <!-- 特价 -->
     <div class="validity" v-if="info.promotionType=='SPECIALOFFER'">
        <span class="ProductLabel SPECIALOFFERLabel" > {{info.promotionTypeName}}</span>
      </div>
       <div class="priceBox" v-show="!userUnvalidated">
          <span class="price">
            {{info.price  | capitalize}}
            </span>
          <del class="delPrice">
            {{info.originalPrice  | capitalize}}
          </del>
        </div>
       
       <div class="priceBox" v-show="userUnvalidated">
          <span class="price">
           ￥会员可见
          </span>
        </div>

    </div>
   
  </el-col>
</template>

<style lang="less" scoped>
@import "~style/index.less";

.item {
  [class*=el-col-]{
    float: none;
  }
  width: 228px;
  background: #fff;
  float: left;
  margin-top: 15px;
  margin-right: 10px;
  padding-left:8px;
  padding-right:8px;
  padding-bottom: 10px;
  position: relative;
  cursor: pointer;
  &:hover{
   box-shadow:4px 6px 6px -4px #dedada;
		// border-radius:7px;
  }
  .ProductLabel{
          border-radius:3px;
          padding:0 5px;
          border-width:1px;
          font-size:12px;
          border-style: solid;
          display: inline-block;
          // max-width: 135px;
          // overflow: hidden;
          // white-space: nowrap;
          // text-overflow: ellipsis;
          // overflow: hidden;
    }
    .FLASH_SALELabel{
  background-color:#F4F4FF  ;
      border-color: #7062F6 ;
      color: #7062F6 ;
    }
    .FLASH_GROUPLabel{
       background-color:#FEECF1 ;
      border-color: #FF5C72  ;
      color: #FF5C72  ;
    }
    .COMBOLabel{
       background-color:#E8F9F6 ;
      border-color: #05AD8A ;
      color: #05AD8A ;
    }
    .ONE_BUYLabel{
      background-color: #ebee97;
      border-color: #7fb12e;
      color: #7fb12e;
    }
    .SPECIALOFFERLabel{
      background-color:#fff6e8;
      border-color: #FF8200 ;
      color: #FF8200 ;
    }
  .imgWrap {
    overflow: hidden;
    position: relative;
    img {
      display: block;
      width: 212px;
      height:212px;
      margin: 10px 0 0 0;
    }
  }
 .countdownBox{
  //  margin-top:15px;
  margin-bottom:4px;
  .CountDown{
    float: left;
  }
 }
  .shopName {
    height:36px;
    color: #333;
    font-size:15px;
    line-height:40px;
    font-weight: 600;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    // overflow:hidden;
    // text-overflow: ellipsis;
    // display: -webkit-box;
    // -webkit-line-clamp:2;
    // -webkit-box-orient: vertical;
  }
  .specification {
    height:20px;
    line-height:20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 12px;
    width: 100%;
      color: #666;
      .Progress {
          background-color: #fee9ea ;
          border-color: #fee9ea ;
          height: 10px;
          border-radius: 5px;
          border-width: 1px;
          border-style: solid;
          display: inline-block;
          width:60%;
          position: relative;
          p{
            position: absolute;
            left: 0;
            top: 0;
             border-radius: 5px;
            background-image: linear-gradient(to right, #FF6D47 , #F43C38);
            height: 10px;
            // width:70%;
          }
        }
         b{
           display: inline-block;
            color: #F43C38 ;
            margin-left: 10px;
          }
  }
   .priceBox {
    margin-top:5px;
    height:26px;
    line-height:26px;
    font-size: 12px;
    color: #999;
    .price{
        color: @theme;
        font-size:16px;
        font-weight: bold;
        display: inline-block;
    }
    .delPrice{
        padding-left:10px;
        color: #929598;
        font-size: 12px;
    }
   }
  .validity {
    line-height:20px;
     font-size: 12px;
     color: #999;
  }
  .hidePrice {
    color: @theme;
    line-height:26px;
    padding-top:6px;
    .price {
      color: @RedTheme;
      font-size:18px;
      line-height:26px;
          font-weight: bold;
    }
  }
}
</style>

<script>
const ProCountDown = () => import("components/product/ProCountDown.vue");
import CartBtn from "@/assets/cartBtn.png";
import defaultImage from "@/assets/defaultImage.png";
import { mapState } from "vuex";
export default {
  name: "ConItem",
  data() {
    return {
      picSuffix:localStorage.getItem("productPic"),
      currentIndex: 0,
      //价格框显示隐藏
      status: false,
      num: 1,
      cartSrc: CartBtn,
      // pics: 1,
      defaultImage:defaultImage,
      promotionMap:'',
        endTime:'',
        startTime:'',
    };
  },
  components: {
    ProCountDown
  },
  props: {
    info: {
      type: Object,
    },
    userUnvalidated:{
      type: Boolean,
    }
  },
  methods: {
// "包邮" "FREE_POSTAGE"
//"A+B促销活动" "AB"
//"套餐活动" "COMBO"
//"预售" "PRESELL"
// "ONE_BUY促销活动" "ONE_BUY"
// "限时购" "FLASH_SALE"
// "限时拼团"   "FLASH_GROUP"
// "特价"  “SPECIALOFFER”

    // "包邮"
FREE_POSTAGEFun(){
        this.$router.push({
          name: "FreeMailDetail",
          query: {
            id: this.info.productId,
            promotionId:  this.info.promotionId,
            isRecentExpiration:  this.info.isRecentExpiration
          },
        });

},
// A+B促销活动  畅享易购
ABFun(){
        this.$router.push({
         name: "ComboDetail",
        query: { productId:  this.info.productId, promotionId:  this.info.promotionId },
      });
},
// 跳转套餐详情
COMBOFun(){
    this.$router.push({
      name: "SetMealDetail",
      query: { promotionId:  this.info.promotionId},
    });
},
// 预售
PRESELLFun(){
  this.$router.push({ name: "ActivityModuleDetail", query: { promotionId:  this.info.promotionId,ActivityType:1 } });
},
// 限时购
FLASH_SALEFun(){
       this.$router.push({
         name: "ActivityModuleDetail",
        query: { id:   this.info.promotionId,ActivityType:2},
      });
},

// 限时拼团
FLASH_GROUPFun(){
 //限时拼团
            this.$router.push({
              name: "FlashGroupList",
            })
},

// 特价  SPECIALOFFERFun
SPECIALOFFERFun(){
   this.$router.push({ name: "ProductDetail", query: { id:  this.info.productId,isRecentExpiration: this.info.isRecentExpiration } });
},

// ONE_BUY   一元购和畅享易购A+B类似
ONE_BUYFun(){
  this.$router.push({
         name: "ONEBUYDetail",
        query: { productId:  this.info.productId, promotionId:  this.info.promotionId },
      });
},
JumpDetail(){
       const actions = new Map([
              ['FREE_POSTAGE',()=>{this.FREE_POSTAGEFun()}],
              ['AB',()=>{this.ABFun()}],
              ['COMBO',()=>{this.COMBOFun()}],
              ['PRESELL',()=>{this.PRESELLFun()}],
              ['FLASH_SALE',()=>{this.FLASH_SALEFun()} ],
              ['SPECIALOFFER',()=>{this.SPECIALOFFERFun()} ],
              ['FLASH_GROUP',()=>{this.FLASH_GROUPFun()} ],
              ['ONE_BUY',()=>{this.ONE_BUYFun()} ],
              
            ])
       let action = [...actions].filter(([key])=>(key == this.info.promotionType ))
       action.forEach(item=>item[1].call(this))
},
      // "包邮"   ItemInfo.promotionType=="FREE_POSTAGE"?FREE_POSTAGEFun():2
      //  this.$router.push({ name: "ProductDetail", query: { id: info.productId,isRecentExpiration:info.isRecentExpiration } });
  },
  mounted(){

  },
  computed: {
    ...mapState({
      isqualification: (state) => state.isqualification,
    }),
  },
  created() {
    
          this.endTime=this.info.endDate
          this.startTime=this.info.beginDate
       
    
  },
  updated() {
  },
};
</script>
